import { configureStore, combineReducers } from '@reduxjs/toolkit'
import formDataReducer from './formDataSlice'
import userDataReducer from './userDataSlice'
import stepReducer from './stepDataSlice'
import answersReducer from './answersSlice'
import screenReducer from './screenSlice'
import errorsReducer from './errorsSlice'
import loadingReducer from './loadingSlice'
import officesReducer from './officesSlice'
import reservedAppointmentReducer from './reservedAppointmentSlice'
import reservedItemsCountSliceReducer from './reservedItemsCountSlice'
import mobileModalSliceReducer from './mobileModalSlice'
import sellPopupReducer from './sellPopupSlice'
import checkReportsReducer from './checkReportsSlice'
import appointmentInfoSliceReducer from './appointmentInfoSlice'
import itemsSliceReducer from './itemsSlice'
import screensReducer from './screensSlice'
import prevScreensHistoryReducer from './prevScreenHistorySlice'
import screenNumberReducer from './screenNumberSlice'
import trackReducer from './trackSlice'
import isLoggedInReducer from './isLoggedInSlice'
import referralDataSliceReducer from './referralDataSlice'
import loginTokenSliceReducer from './loginTokenSlice'
import reportNumbersReducer from './reportNumbersSlice'
import labNameReducer from './labNameSlice'
import formSubmittedReducer from './formSubmittedSlice'
import welcomeBackReducer from './welcomeBackSlice'
import closeBtnWasClickedReducer from './closeBtnWasClickedSlice'
import nextScreenReducer from './nextScreenSlice'
import skipReducer from './skipSlice'
import trackDataReducer from './trackDataSlice'
import isVipOfferReducer from './isVipOfferSlice'
import itemParamsReducer from './itemsParamsSlice'
import bulkItemsReducer from './bulkItemsSlice'
import isFetchingItemParamsReducer from './isFetchingItemParams'
import photoViaMobileReducer from './photoViaMobile'
import offersReducer from './offersSlice'
import galleryPopupReducer from './galleryPopupSlice'
import editingItemReducer from './editingItemSlice'
import addingExtraItemReducer from './addingExtraItemSlice'
import addItemInfoModeReducer from './addItemInfoModeSlice'
import editingNoteReducer from './editingNoteSlice'
import pauseFlowPopupReducer from './pauseFlowPopupSlice'
import appointmentsReducer from './appointmentsSlice'
import offerResponseReducer from './offerResponseSlice'
import dealsReducer from './dealsSlice'
import cartReducer from './cartSlice'
import submissionSavedPopupReducer from './submissionSavedPopupSlice'
import incompleteSessionReducer from './incompleteSessionSlice'
import appointmentOfficeModalReducer from './appointmentOfficeModalSlice'
import faqPopupSReducer from './faqPopupSlice'
import appointmentItemsReducer from './appointmentItemsSlice'
import platformInfoReducer from './platformInfoSlice'
import prevAnalyticsTokenReducer from './prevAnalyticsTokenSlice'
import isShortFlowReducer from './isShortFlowSlice'
import siteLanguageReducer from './siteLanguageSlice'
import accountNoteIdReducer from './accountNoteIdSlice'
import lastItemTypeReducer from './lastItemTypeSlice'
import resetPasswordReducer from './resetPasswordSlice'
import allLocationsCountReducer from './allLocationsCountSlice'
import profileParamsSliceReducer from './profileParamsSlice'
import multipleScreensTestDataSliceReducer from './multipleScreensTestDataSlice'
import fullStorySliceReducer from './fullStorySlice'
import calendarSliceReducer from './calendarSlice'
import bookAppointmentBtnSliceReducer from './bookAppointmentBtnSlice'

const rootReducer = combineReducers({
  isLoggedIn: isLoggedInReducer,
  formData: formDataReducer,
  user: userDataReducer,
  step: stepReducer,
  currentScreen: screenReducer,
  screenNumber: screenNumberReducer,
  screens: screensReducer,
  prevScreensHistory: prevScreensHistoryReducer,
  answers: answersReducer,
  errors: errorsReducer,
  loading: loadingReducer,
  offices: officesReducer,
  reservedAppointment: reservedAppointmentReducer,
  reservedItemsCount: reservedItemsCountSliceReducer,
  mobileModal: mobileModalSliceReducer,
  sellPopup: sellPopupReducer,
  galleryPopup: galleryPopupReducer,
  pauseFlowPopup: pauseFlowPopupReducer,
  track: trackReducer,
  checkReports: checkReportsReducer,
  appointmentInfo: appointmentInfoSliceReducer,
  items: itemsSliceReducer,
  referralData: referralDataSliceReducer,
  loginToken: loginTokenSliceReducer,
  reportNumbers: reportNumbersReducer,
  labName: labNameReducer,
  formSubmitted: formSubmittedReducer,
  welcomeBack: welcomeBackReducer,
  closeBtnWasClicked: closeBtnWasClickedReducer,
  nextScreen: nextScreenReducer,
  skip: skipReducer,
  trackData: trackDataReducer,
  isVipOffer: isVipOfferReducer,
  itemParams: itemParamsReducer,
  bulkItems: bulkItemsReducer,
  isFetchingItemParams: isFetchingItemParamsReducer,
  photoViaMobile: photoViaMobileReducer,
  offers: offersReducer,
  editingItem: editingItemReducer,
  addingExtraItem: addingExtraItemReducer,
  addItemAdditionalInfo: addItemInfoModeReducer,
  editingNote: editingNoteReducer,
  appointments: appointmentsReducer,
  offerResponse: offerResponseReducer,
  deals: dealsReducer,
  openCart: cartReducer,
  submissionSavedPopup: submissionSavedPopupReducer,
  incompleteSession: incompleteSessionReducer,
  appointmentOfficeModal: appointmentOfficeModalReducer,
  faqPopup: faqPopupSReducer,
  appointmentItems: appointmentItemsReducer,
  platformInfo: platformInfoReducer,
  prevAnalyticsToken: prevAnalyticsTokenReducer,
  isShortFlow: isShortFlowReducer,
  siteLanguage: siteLanguageReducer,
  accountNoteId: accountNoteIdReducer,
  lastItemType: lastItemTypeReducer,
  resetPasswordData: resetPasswordReducer,
  allLocationsCount: allLocationsCountReducer,
  profileParams: profileParamsSliceReducer,
  multipleScreensTestData: multipleScreensTestDataSliceReducer,
  fullStoryData: fullStorySliceReducer,
  calendarData: calendarSliceReducer,
  bookAppointmentBtn: bookAppointmentBtnSliceReducer
})

export const store = configureStore({
  reducer: rootReducer,
})
